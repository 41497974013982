<template>
  <v-dialog
    v-model="modal"
    max-width="960px"
  >
    <template #activator="{ on }">
      <v-icon
        v-if="teamMember"
        data-cy="editMember-button"
        v-on="on"
      >
        mdi-pencil
      </v-icon>
      <v-btn
        v-else
        rounded
        depressed
        color="primary"
        class="ml-auto"
        data-cy="add-member-button"
        v-on="on"
      >
        <v-icon>
          mdi-account-plus
        </v-icon>
        <span v-t="'team.addMember'" />
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span
          v-t="teamMember ? 'team.editTeamMember' : 'team.addMember'"
          class="headline"
        />
        <v-spacer />
        <v-icon
          data-cy="change-plan-modal-close"
          @click="closeModal()"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <material-team-members-form
          v-if="modal"
          :on-submit="onSubmit"
          :team-member="teamMember"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { isEmpty } from 'lodash'
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    teamMember: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      modal: false
    }
  },
  computed: {
    ...mapState('member', ['errors'])
  },
  methods: {
    ...mapActions('member', ['createTeamMember', 'editTeamMember']),
    closeModal () {
      this.modal = false
    },
    async onSubmit (data) {
      const { clientId } = this.$route.params
      const submitFn = this.teamMember ? this.editTeamMember : this.createTeamMember
      const requestPayload = {
        clientId,
        ...data
      }

      if (this.teamMember) {
        requestPayload.guid = this.teamMember.guid
      }

      await submitFn(requestPayload)

      if (isEmpty(this.errors)) {
        this.closeModal()
      }
    }
  }
}
</script>
